import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { useIntl } from 'react-intl'
import { NativeSyntheticEvent, Platform, Pressable, TextInputChangeEventData, ViewStyle } from 'react-native'

import { isString } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import {
  isValidLength,
  isValidLowerCase,
  isValidNumber,
  isValidUpperCase,
  PasswordPolicy,
  PasswordPolicyMet,
} from '@lyrahealth-inc/shared-app-logic'

import { PasswordChecklist } from './PasswordChecklist'
import { IS_WEB } from '../../../constants'
import { EyeIcon } from '../../icons/EyeIcon'
import { InputField, InputFieldProps } from '../InputField'

export interface PasswordFieldProps
  extends Pick<
    InputFieldProps,
    'setInputRefs' | 'returnKeyType' | 'areAllInputRefsSet' | 'getReturnKeyType' | 'navigateToNewField'
  > {
  name: string
  label?: string
  error?: string
  active?: boolean
  enablePasswordChecklist: boolean
  passwordPolicy?: PasswordPolicy
  onChange: (value: string) => void
  onBlur?: () => void
  onFocus?: () => void
  baseInputStyle?: ViewStyle
  inputContainerStyle?: ViewStyle
  showPasswordChecklistByDefault?: boolean
  hidePasswordChecklistOnBlur?: boolean
  autoComplete?: 'current-password' | 'new-password'
  inputAccessoryViewID?: string
}

const Container = styled.View({
  width: '100%',
  zIndex: 1,
})

export const PasswordField: FunctionComponent<PasswordFieldProps> = ({
  name,
  label,
  error,
  active,
  enablePasswordChecklist,
  passwordPolicy,
  onChange = () => {},
  onBlur = () => {},
  onFocus = () => {},
  baseInputStyle,
  inputContainerStyle,
  showPasswordChecklistByDefault,
  hidePasswordChecklistOnBlur,
  autoComplete = 'current-password',
  inputAccessoryViewID,
  returnKeyType,
  areAllInputRefsSet,
  setInputRefs,
  navigateToNewField,
  getReturnKeyType,
}) => {
  const {
    breakpoints: { isMobileSized },
    colors,
  } = useTheme()

  const [showPassword, setShowPassword] = useState(false)
  const [shouldShowPasswordChecklist, setShouldShowPasswordChecklist] = useState(showPasswordChecklistByDefault)

  const { minLength, minLowerCase, minUpperCase, minNumber } = passwordPolicy || {}
  const [passwordStrength, setPasswordStrength] = useState<PasswordPolicyMet>({
    minUpperCase: !passwordPolicy, // Init true to skip password validation
    minLowerCase: !passwordPolicy,
    minNumber: !passwordPolicy,
    minLength: !passwordPolicy,
  })

  const fieldRef = useRef<any>(null)

  const handleShowPasswordChecklist = (show: boolean) => {
    show
      ? enablePasswordChecklist && !shouldShowPasswordChecklist && setShouldShowPasswordChecklist(true)
      : hidePasswordChecklistOnBlur && setShouldShowPasswordChecklist(false)
  }

  const handleFocus = () => {
    if (isMobileSized && IS_WEB) {
      // scrollIntoView is only used for web and not native
      // native is taken care of by using KeyboardAwareScrollView in SetUpAccountForm
      fieldRef?.current?.scrollIntoView({ behavior: 'smooth' })
    }
    onFocus()
    handleShowPasswordChecklist(true)
  }

  const handleBlur = () => {
    onBlur()
    hidePasswordChecklistOnBlur ? handleShowPasswordChecklist(false) : null
  }

  const handleChange = (e: NativeSyntheticEvent<TextInputChangeEventData>) => {
    const value = e.nativeEvent.text
    if (passwordPolicy) {
      setPasswordStrength({
        minUpperCase: isValidUpperCase(value, minUpperCase!),
        minLowerCase: isValidLowerCase(value, minLowerCase!),
        minNumber: isValidNumber(value, minNumber!),
        minLength: isValidLength(value, minLength!),
      })
    }
    onChange(value)
  }

  // this logic allows user to navigate to PasswordField from keyboard(mobile)
  useEffect(() => {
    if (setInputRefs && fieldRef && name) {
      setInputRefs({ name, ref: fieldRef })
    }
  }, [name, setInputRefs])

  return (
    <Container ref={fieldRef}>
      <InputField
        name={name}
        label={label}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        secureTextEntry={!showPassword}
        error={error}
        active={active}
        baseInputStyle={baseInputStyle}
        textContentType='password'
        importantForAutofill='yes'
        /**
         * letterSpacing does not seem to work on android and causing the text input to disappear
         * when being set to 1. There is no mention of letterSpacing working on either iOS or Android,
         * https://reactnative.dev/docs/textinput#style, however it does seem to behave correctly on iOS
         */
        typeStyle={{
          letterSpacing: !showPassword && Platform.OS !== 'android' ? 1 : 0,
          fontSize: 16,
          color: colors.textPrimary,
        }}
        inputContainerStyle={inputContainerStyle}
        autoComplete={autoComplete}
        inputAccessoryViewID={inputAccessoryViewID}
        areAllInputRefsSet={areAllInputRefsSet}
        returnKeyType={returnKeyType}
        getReturnKeyType={getReturnKeyType}
        navigateToNewField={navigateToNewField}
      >
        <Pressable
          testID={'PasswordField-showPasswordToggle'}
          onPress={() => setShowPassword(!showPassword)}
          onFocus={() => handleShowPasswordChecklist(true)}
          onBlur={() => (hidePasswordChecklistOnBlur ? handleBlur() : null)}
        >
          <EyeIcon isOpen={showPassword} />
        </Pressable>
      </InputField>
      {shouldShowPasswordChecklist && passwordPolicy && (
        <PasswordChecklist
          passwordPolicy={passwordPolicy}
          passwordStrength={passwordStrength}
          customStyle={
            hidePasswordChecklistOnBlur
              ? {
                  position: 'absolute',
                  width: '100%',
                  top: Platform.OS === 'android' ? 84 : 80,
                  padding: 16,
                  border: `1px solid ${colors.borderDefault}`,
                  borderRadius: 8,
                  shadowColor: colors.outlineHigh,
                  shadowOpacity: '0.25',
                  shadowRadius: 8,
                }
              : {}
          }
        />
      )}
    </Container>
  )
}

export const PasswordFieldRFF: FunctionComponent<FieldRenderProps<string>> = ({
  input: { onChange, name, onFocus, onBlur },
  meta: { error, active, touched, submitFailed },
  label,
  passwordPolicy,
  showPasswordChecklist = true,
  validateOnTouch = false,
  baseInputStyle,
  inputContainerStyle,
  showPasswordChecklistByDefault,
  hidePasswordChecklistOnBlur,
  autoComplete,
  inputAccessoryViewID,
  handleOnInputFocus,
  setInputRefs,
  handleOnInputBlur,
  getReturnKeyType,
  returnKeyType,
  navigateToNewField,
  areAllInputRefsSet,
}) => {
  const { formatMessage } = useIntl()

  let errorValue = ''
  if ((validateOnTouch && touched && error) || (submitFailed && error)) {
    errorValue = isString(error) ? error : formatMessage(error)
  }

  return (
    <PasswordField
      name={name}
      label={label}
      error={errorValue}
      active={active}
      enablePasswordChecklist={showPasswordChecklist}
      passwordPolicy={passwordPolicy}
      onChange={onChange}
      onFocus={() => {
        if (handleOnInputFocus) {
          handleOnInputFocus(name)
        }
        onFocus()
      }}
      onBlur={() => {
        if (handleOnInputBlur) {
          handleOnInputBlur(name)
        }
        onBlur()
      }}
      baseInputStyle={baseInputStyle}
      inputContainerStyle={inputContainerStyle}
      showPasswordChecklistByDefault={showPasswordChecklistByDefault}
      hidePasswordChecklistOnBlur={hidePasswordChecklistOnBlur}
      autoComplete={autoComplete}
      inputAccessoryViewID={inputAccessoryViewID}
      setInputRefs={setInputRefs}
      returnKeyType={returnKeyType}
      areAllInputRefsSet={areAllInputRefsSet}
      getReturnKeyType={getReturnKeyType}
      navigateToNewField={navigateToNewField}
    />
  )
}
