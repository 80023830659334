import React, { FunctionComponent } from 'react'
import Svg, { Circle } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { ThemeType } from '../../utils/themes/ThemeProvider'

export interface BulletIconProps {
  size?: number
  fillColor?: string
}

export const BulletIcon: FunctionComponent<BulletIconProps> = ({ size = 6, fillColor }) => {
  const { colors } = useTheme() as ThemeType
  const { fill: bulletColor } = colors.components.bulletedList
  return (
    <Svg width={size} height={size} fill='none'>
      <Circle cx={3} cy={3} r={3} fill={fillColor ?? bulletColor} />
    </Svg>
  )
}
