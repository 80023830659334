import React, { FunctionComponent } from 'react'

import { useTheme } from 'styled-components/native'

import WellnessCheckInBackgroundDesktop from '../../assets/WellnessCheckInBackgroundDesktop.png'
import WellnessCheckInBackgroundMobile from '../../assets/WellnessCheckInBackgroundMobile.png'
import WellnessCheckInBackgroundTablet from '../../assets/WellnessCheckInBackgroundTablet.png'
import { ThemeType } from '../../utils/themes/ThemeProvider'
import { BackgroundImageContainer } from '../backgroundImageContainer/BackgroundImageContainer'

export const WellnessCheckInBackgroundContainer: FunctionComponent = ({ children }) => {
  const {
    breakpoints: { isMobileSized, isMinWidthLaptop },
  } = useTheme() as ThemeType

  return (
    <BackgroundImageContainer
      backgroundImageUrl={
        isMobileSized
          ? WellnessCheckInBackgroundMobile
          : isMinWidthLaptop
          ? WellnessCheckInBackgroundDesktop
          : WellnessCheckInBackgroundTablet
      }
      imageWidth={1990}
      imageHeight={1800}
    >
      {children}
    </BackgroundImageContainer>
  )
}
