import React, { KeyboardEvent, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import CSSModules from 'react-css-modules'
import { FormattedMessage, IntlShape, useIntl } from 'react-intl'
import { connect, ConnectedProps } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Element } from 'react-scroll'

import { isAfter, sub } from 'date-fns'
import FocusTrap from 'focus-trap-react'
import { List, Map } from 'immutable'
import { get, isEmpty, isNil } from 'lodash-es'
import { AnyAction, bindActionCreators, Dispatch } from 'redux'

import {
  Appointment,
  BCX_EVENTS,
  CONTACT_CARE_TEAM_REQUEST_TYPES,
  ConversationObject,
  Episode,
  getChatHeaderSubTitleMsg,
  hasActiveEpisode,
  Message,
  MessageAttributes,
  MessageConversation,
  parseAllowedMessages,
  Provider,
  ProviderInfo,
  useApptFormattedTime,
} from '@lyrahealth-inc/shared-app-logic'
import {
  Avatar,
  ChevronIcon,
  MessageIcon,
  stringUtils,
  Truncate,
  useNewMessageSound,
  useSaveMessageDraft,
  useWindowVisible,
  XIcon,
} from '@lyrahealth-inc/ui-core'
import {
  ActivityReviewInfo,
  Chat,
  MessageAttachmentData,
  MessageAttachmentType,
  MessageType,
  toJS,
} from '@lyrahealth-inc/ui-core-crossplatform'

import styles from './messagesPopover.module.scss'
import { getCurrentCountryEmergencyNumbersList } from '../../../data/currentCountry/currentCountrySelectors'
import { getCustomerPhone } from '../../../data/customer/customerSelectors'
import { getEpisodes } from '../../../data/lyraTherapy/lyraTherapySelectors'
import { MIXPANEL_PAGE } from '../../../data/mixpanel'
import * as MixpanelActions from '../../../data/mixpanel'
import { goToContactCareTeam } from '../../onboard/contactCareTeam/data/contactCareTeamUtils'
import {
  deselectMessengerProvider,
  getMessage,
  getMessages,
  incrementUnreadMessageCount,
  saveMessageDraft,
  selectMessengerProvider,
  setLiveMsgProviderIdToOpen,
  submitMessage,
  toggleLiveMsgSession,
  toggleShownLiveMsgModal,
  updateSelectedConversationId,
  updateUnreadMessageCount,
} from '../data/LyraTherapyActions'

export enum MessageActions {
  ON_TYPING = 'on_typing',
  PROVIDER_SELECTED = 'provider_selected',
  READ_MESSAGE = 'read_message',
  NEW_MESSAGE = 'new_message',
  IS_MOBILE = 'is_mobile',
  POPOVER_CHANGE = 'popover_change',
  SET_INPUT = 'set_input',
}

export type Action =
  | { type: MessageActions.ON_TYPING; receiverTyping: boolean }
  | { type: MessageActions.PROVIDER_SELECTED }
  | { type: MessageActions.READ_MESSAGE }
  | { type: MessageActions.NEW_MESSAGE }
  | { type: MessageActions.IS_MOBILE }
  | { type: MessageActions.POPOVER_CHANGE; isPopoverOpen: boolean }
  | { type: MessageActions.SET_INPUT; inputValue: string }

type State = {
  isPopoverOpen: boolean
  inputValue: string
  receiverTyping: boolean
  isMobile: boolean
  newMessageCount: number
}

export type MessagesPopoverProps = ConnectedProps<typeof connector> & {
  iconSize: number
  iconBadgeStyles: Dict
  iconFilled?: boolean
  isSessionControl?: boolean
  iconFillColor?: string
  customIconStyles?: Dict
  customIconOpenStyles?: Dict
  modalStyle?: Dict
}

const messageReducer = (state: State, action: Action) => {
  switch (action.type) {
    case MessageActions.ON_TYPING:
      return { ...state, receiverTyping: action.receiverTyping }
    case MessageActions.PROVIDER_SELECTED:
      return { ...state, newMessageCount: 0 }
    case MessageActions.READ_MESSAGE:
      return { ...state, newMessageCount: 0 }
    case MessageActions.NEW_MESSAGE:
      return { ...state, newMessageCount: state.newMessageCount + 1, receiverTyping: false }
    case MessageActions.IS_MOBILE:
      return { ...state, isMobile: window.innerWidth < 992 }
    case MessageActions.POPOVER_CHANGE:
      if (action.isPopoverOpen) {
        // open
        return { ...state, isPopoverOpen: action.isPopoverOpen }
      }
      // close
      return { ...state, isPopoverOpen: false, newMessageCount: 0, receiverTyping: false }
    case MessageActions.SET_INPUT:
      return { ...state, inputValue: action.inputValue }
    default:
      throw new Error(`Unhandled action type - ${JSON.stringify(action)}`)
  }
}

const MessagesPopover: React.FC<MessagesPopoverProps> = ({
  userId,
  conversationsClient,
  conversations,
  selectedConversation,
  selectedProvider,
  providers,
  messages,
  iconSize,
  iconBadgeStyles,
  selectedDraft,
  iconFilled = false,
  inLiveMsgSession,
  activeLiveMsgAppt,
  liveMsgProviderId,
  liveMsgProviderIdToOpen,
  shownLiveMsgModal,
  selectedConversationId,
  contactCarePhoneNumber,
  emergencyPhoneNumber,
  customIconStyles,
  isSessionControl = false,
  episodes,
  iconFillColor,
  customIconOpenStyles,
  modalStyle,
  actions: {
    updateUnreadMessageCount,
    getMessages,
    getMessage,
    incrementUnreadMessageCount,
    submitMessage,
    deselectMessengerProvider,
    selectMessengerProvider,
    trackEventWithObj,
    saveMessageDraft,
    toggleLiveMsgSession,
    setLiveMsgProviderIdToOpen,
    toggleShownLiveMsgModal,
    updateSelectedConversationId,
  },
}) => {
  const intl: IntlShape = useIntl()
  const [loading, setLoading] = useState(false)
  const messageContainer = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()

  const [state, dispatch] = useReducer(messageReducer, {
    isPopoverOpen: false,
    inputValue: '',
    receiverTyping: false,
    isMobile: false,
    newMessageCount: 0,
  })

  const { isPopoverOpen, inputValue, receiverTyping, isMobile, newMessageCount } = state
  const selectedProviderLyraId: string = selectedProvider?.lyra_id
  // memoized save draft with updated inputValue
  const { saveDraft } = useSaveMessageDraft({ saveMessageDraft, inputValue, selectedConversationId })

  const [playSound] = useNewMessageSound()
  const [windowVisible] = useWindowVisible()

  // tell the conversation that we are typing
  const onTyping: () => Promise<void> = useCallback(async () => {
    if (conversationsClient) {
      const activeConversation = await conversationsClient.getConversationBySid(selectedConversationId)
      activeConversation.typing()
    }
  }, [conversationsClient, selectedConversationId])

  const onReceiverTyping: (member: {
    conversation: {
      sid: string
    }
    isTyping: boolean
  }) => void = useCallback(
    (member) => {
      // ensure that the typing started event comes from the selected conversation
      if (member.conversation.sid === selectedConversationId) {
        dispatch({ type: MessageActions.ON_TYPING, receiverTyping: member.isTyping })
      }
    },
    [selectedConversationId],
  )

  const markMessagesAsRead: () => void = useCallback(() => {
    dispatch({ type: MessageActions.READ_MESSAGE })
    updateUnreadMessageCount(selectedConversationId, { unread_patient_messages_count: 0 })
  }, [updateUnreadMessageCount, selectedConversationId])

  const onNewMessage: (message: { conversation: { sid: string }; sid: string }) => void = useCallback(
    (message) => {
      const conversationId = message.conversation.sid

      // If message is from self - do nothing
      if (get(message, 'state.attributes.from') === userId) {
        return
      }
      // If the messages have been fetched then add the message
      if (messages?.[conversationId]) {
        ;(getMessage({ conversationId, messageId: message.sid }) as any).then(() => {
          // If the conversation is being viewed add an unread message pill
          if (isPopoverOpen && conversationId === selectedConversationId) {
            dispatch({ type: MessageActions.NEW_MESSAGE })
          }
        })
      }
      // if popover is not open and window is not visible, when a new message arrives, play a sound
      if (!isPopoverOpen || !windowVisible) {
        playSound()
      }
      // Always increment counter on new message.
      incrementUnreadMessageCount(conversationId)
    },
    [
      userId,
      incrementUnreadMessageCount,
      selectedConversationId,
      isPopoverOpen,
      getMessage,
      messages,
      playSound,
      windowVisible,
    ],
  )

  const checkForMobile: () => void = () => dispatch({ type: MessageActions.IS_MOBILE })

  useEffect(() => {
    checkForMobile()
    window.addEventListener('resize', checkForMobile)
    return () => window.removeEventListener('resize', checkForMobile)
  }, [])

  useEffect(() => {
    if (isMobile && providers.length > 1) {
      deselectMessengerProvider()
    }
  }, [providers.length, isMobile, deselectMessengerProvider])

  const setInputValue = useCallback(
    (value) => dispatch({ type: MessageActions.SET_INPUT, inputValue: value ?? '' }),
    [],
  )

  useEffect(() => {
    if (selectedProviderLyraId) {
      setInputValue(selectedDraft)
    }
  }, [selectedProviderLyraId, selectedDraft, setInputValue])

  const closePopover: () => void = useCallback(() => {
    // user has left the session
    if (inLiveMsgSession) toggleLiveMsgSession(false)
    saveDraft()
    dispatch({ type: MessageActions.POPOVER_CHANGE, isPopoverOpen: false })
    if (isMobile && providers.length > 1) {
      deselectMessengerProvider()
    }
  }, [toggleLiveMsgSession, deselectMessengerProvider, providers.length, inLiveMsgSession, isMobile, saveDraft])

  const handleOutsideClick: (e: any) => void = useCallback(
    (e) => {
      if (messageContainer && !messageContainer?.current?.contains(e.target) && isPopoverOpen) {
        closePopover()
      }
    },
    [isPopoverOpen, closePopover],
  )

  useEffect(() => {
    if (conversationsClient) {
      conversationsClient.on('messageAdded', onNewMessage)
      conversationsClient.on('typingStarted', onReceiverTyping)
      conversationsClient.on('typingEnded', onReceiverTyping)
    }
    document.addEventListener('mousedown', handleOutsideClick, false)
    return () => {
      if (conversationsClient) {
        conversationsClient.off('messageAdded', onNewMessage)
        conversationsClient.off('typingStarted', onReceiverTyping)
        conversationsClient.off('typingEnded', onReceiverTyping)
      }
      document.removeEventListener('mousedown', handleOutsideClick, false)
    }
  }, [conversationsClient, onNewMessage, onReceiverTyping, handleOutsideClick])

  const getProviderConversation = useCallback(
    (providerId) => {
      const conversation = conversations.find((conversation) => conversation?.provider_lyra_id === providerId)
      return conversation
    },
    [conversations],
  )

  const getLatestMessages: (providerId: string) => void = useCallback(
    (providerId) => {
      const conversationId = getProviderConversation(providerId)?.conversation_id
      setLoading(true)
      ;(getMessages(conversationId!) as any)
        .then(() => {
          updateUnreadMessageCount(conversationId!, { unread_patient_messages_count: 0 })
        })
        .finally(() => setLoading(false))
    },
    [getProviderConversation, getMessages, updateUnreadMessageCount],
  )

  const submitNewMessage: (value: string) => void = useCallback(
    (value) => {
      const submitData = {
        body: value,
        type: 'direct',
        client_id: userId,
      }
      dispatch({ type: MessageActions.SET_INPUT, inputValue: '' })
      saveMessageDraft({ content: '', conversationId: selectedConversationId })
      ;(submitMessage(selectedConversationId, submitData as any) as any).then(
        (response: { new_conversation_id: string }) => {
          // Update the conversation id if the message is in a previously closed conversation
          if (response?.new_conversation_id) {
            updateSelectedConversationId({
              oldConversationId: selectedConversationId,
              newConversationId: response.new_conversation_id,
            })
          }
        },
      )

      trackEventWithObj({
        event: BCX_EVENTS.SEND_MESSAGE,
        page: MixpanelActions.MIXPANEL_PAGE.MESSAGES,
      })
    },
    [trackEventWithObj, selectedConversationId, userId, saveMessageDraft, submitMessage, updateSelectedConversationId],
  )

  const openPopover: () => void = useCallback(() => {
    const conversationId = selectedConversation?.conversation_id
    if (liveMsgProviderId && !inLiveMsgSession) toggleLiveMsgSession(true)
    dispatch({ type: MessageActions.POPOVER_CHANGE, isPopoverOpen: true })
    if (!isEmpty(selectedConversation) && !messages?.[conversationId]) {
      getLatestMessages(selectedConversation?.provider_lyra_id)
      // TODO: probably need to change tracking here
      trackEventWithObj({
        event: MixpanelActions.MIXPANEL_EVENTS.PAGE_LOAD,
        page: MixpanelActions.MIXPANEL_PAGE.MESSAGES,
      })
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    } else if (selectedConversation?.conversation_attributes?.unread_patient_messages_count > 0) {
      updateUnreadMessageCount(conversationId, { unread_patient_messages_count: 0 })
    }
  }, [
    liveMsgProviderId,
    inLiveMsgSession,
    getLatestMessages,
    trackEventWithObj,
    updateUnreadMessageCount,
    selectedConversation,
    messages,
    toggleLiveMsgSession,
  ])

  const togglePopover: () => void = useCallback(() => {
    if (isSessionControl) {
      trackEventWithObj({
        page: MixpanelActions.MIXPANEL_PAGE.VIDEO_SESSION,
        event: MixpanelActions.MIXPANEL_EVENTS.BUTTON_PRESS,
        action: MixpanelActions.MIXPANEL_ACTION.TOGGLE_MESSENGER,
        details: MixpanelActions.MIXPANEL_USER_TYPE.CLIENT,
      })
    }

    if (!isPopoverOpen) {
      if (!shownLiveMsgModal && liveMsgProviderId) {
        trackEventWithObj({
          page: MixpanelActions.MIXPANEL_PAGE.MESSAGES,
          event: MixpanelActions.MIXPANEL_EVENTS.LIVE_MESSAGE_PRIMER,
        })
        toggleShownLiveMsgModal(true)
      } else {
        openPopover()
      }
    } else {
      closePopover()
    }
  }, [
    isPopoverOpen,
    isSessionControl,
    shownLiveMsgModal,
    liveMsgProviderId,
    toggleShownLiveMsgModal,
    closePopover,
    openPopover,
    trackEventWithObj,
  ])

  const onChatBubblePressed = useCallback(
    (_messageId: string, _receiver: boolean, activityInfo?: ActivityReviewInfo) => {
      switch (activityInfo?.activityType) {
        case 'feedback':
          trackEventWithObj({
            event: MixpanelActions.MIXPANEL_EVENTS.BUTTON_PRESS,
            page: MixpanelActions.MIXPANEL_PAGE.MESSAGES,
            action: MixpanelActions.MIXPANEL_ACTION.OPEN_ACTIVITY_ENTRY,
          })
          navigate(`/secure/index/assignments/${activityInfo.id}`, {
            state: { responseId: activityInfo.responseId },
          })
          break
        case 'session_cancellation':
        case 'request_session_time':
          navigate('/secure/index/sessions')
          break
        default:
          trackEventWithObj({
            event: MixpanelActions.MIXPANEL_EVENTS.BUTTON_PRESS,
            page: MixpanelActions.MIXPANEL_PAGE.MESSAGES,
            action: MixpanelActions.MIXPANEL_ACTION.OPEN_MESSAGE_URL,
          })
      }
      closePopover()
    },
    [closePopover, navigate, trackEventWithObj],
  )

  const selectProviderConversation: (providerId: string) => void = useCallback(
    (providerId) => {
      const providerConversation = getProviderConversation(providerId)
      // save drafts when switching between providers
      saveMessageDraft({ content: inputValue, conversationId: selectedConversationId })
      if (inLiveMsgSession) toggleLiveMsgSession(liveMsgProviderId === providerId)
      dispatch({ type: MessageActions.PROVIDER_SELECTED })
      selectMessengerProvider(providerId)
      if (providerConversation) {
        const providerConversationId: string = providerConversation?.conversation_id
        if (!messages?.[providerConversationId] && providerConversationId) {
          getLatestMessages(providerId)
        } else if (providerConversation?.conversation_attributes?.unread_patient_messages_count > 0) {
          updateUnreadMessageCount(providerConversationId, { unread_patient_messages_count: 0 })
        }
      }
    },
    [
      liveMsgProviderId,
      getLatestMessages,
      updateUnreadMessageCount,
      saveMessageDraft,
      getProviderConversation,
      inLiveMsgSession,
      inputValue,
      messages,
      selectMessengerProvider,
      selectedConversationId,
      toggleLiveMsgSession,
    ],
  )

  // effect to start live msg session from banner
  useEffect(() => {
    if (liveMsgProviderIdToOpen) {
      openPopover()
      selectProviderConversation(liveMsgProviderIdToOpen)
      setLiveMsgProviderIdToOpen(null)
    }
  }, [liveMsgProviderIdToOpen, setLiveMsgProviderIdToOpen, openPopover, selectProviderConversation])

  const renderProviderList = (): JSX.Element | void => {
    const hideList: boolean = isMobile && !isEmpty(selectedProvider)
    if (providers.length > 1) {
      return (
        <div styleName={`provider-list ${hideList ? 'hide' : ''}`}>
          <div styleName='top-bar'>
            <div styleName='list-title'>
              <FormattedMessage defaultMessage='Messages' description='displayed title atop the message list' />
            </div>
            <XIcon
              styleName='close-icon'
              onClick={closePopover}
              isFilled
              fillColor={styles.x_semi_dark_gray}
              data-test-id='Messenger-close'
            />
          </div>
          {providers.map((providerObj) => {
            const provider: ProviderInfo = providerObj?.provider
            const providerId: string = provider?.lyra_id
            const providerConversation = getProviderConversation(providerId)
            const providerInitials = stringUtils.getInitials(provider?.display_name)
            const unreadCount: number =
              providerConversation?.conversation_attributes?.unread_patient_messages_count ?? 0
            const isSelected: boolean = selectedProviderLyraId === providerId
            return (
              <button
                key={providerId}
                styleName={`list-item ${isSelected ? 'selected' : ''}`}
                data-test-id='Messenger-providerList-item'
                onClick={() => selectProviderConversation(providerId)}
              >
                <div styleName='content-container'>
                  <Avatar ltStyle src={provider?.lyraProviderImage?.imageUrl} size={38}>
                    {providerInitials}
                  </Avatar>
                  <div styleName='header'>
                    <Truncate noToggle content={provider?.display_name} lines={1} />
                  </div>
                  {!isSelected && unreadCount > 0 ? <div styleName='unread-badge'>{unreadCount}</div> : null}
                </div>
                <ChevronIcon styleName='list-chevron' isFilled width={15} fillColor={styles.x_medium_gray} />
              </button>
            )
          })}
        </div>
      )
    }
  }

  const selectedMessages = messages?.[selectedConversationId]?.conversation_messages
  const getMessageType = (attributes: MessageAttributes) => {
    const linkText = attributes.metadata?.linkText
    const activityResponseId = attributes.metadata?.activity_response_id
    const activityId = attributes.metadata?.activity_id
    const isDeletedMessage =
      !isNil(attributes?.retention_status) && attributes?.retention_status === 'DELETED_LIVE_MESSAGE'
    const isActivity = Boolean(linkText) || (Boolean(activityResponseId) && Boolean(activityId))

    if (isDeletedMessage) {
      return MessageType.DELETED
    } else if (isActivity) {
      return MessageType.ACTIVITY
    } else {
      return MessageType.CHAT_BUBBLE
    }
  }

  const data = useMemo(() => {
    if (isEmpty(selectedMessages)) return []
    const msgs = parseAllowedMessages(selectedMessages).map((m, index) => {
      const attributes = JSON.parse(m.attributes)
      const isPatient = attributes.author_type === 'patient'
      const previousMessage = selectedMessages[index - 1]
      let showTime = true
      const dateCreated = new Date(m.date_created)
      // we only want to show the time stamp if 10 minutes have passed
      showTime = isAfter(sub(dateCreated, { minutes: 10 }), new Date(previousMessage?.date_created))
      return {
        messageId: m.message_id,
        receiver: !isPatient,
        dateCreated: m?.date_created,
        message: m.body,
        isLatestMessage: index === selectedMessages.length - 1,
        // if the message has no status, it has been sent
        sendStatus: m.sendStatus === undefined ? 'sent' : m.sendStatus,
        showTime,
        linkText: attributes.metadata?.linkText,
        activityResponseId: attributes.metadata?.activity_response_id,
        activityId: attributes.metadata?.activity_id,
        submitDate: attributes.metadata?.submit_date,
        activityTitle: attributes.metadata?.title,
        type: getMessageType(attributes),
        messageType: attributes.message_type,
        attachments: attributes.metadata?.attachments,
        avatarDetails: !isPatient
          ? {
              src: selectedProvider?.lyraProviderImage?.imageUrl,
              displayName: selectedProvider?.display_name,
            }
          : undefined,
      }
    })
    return msgs.reverse()
  }, [selectedMessages, selectedProvider?.display_name, selectedProvider?.lyraProviderImage?.imageUrl])
  const { sessionTime } = useApptFormattedTime(activeLiveMsgAppt)

  const hideMessenger: boolean = isMobile && isEmpty(selectedProvider)
  // add all unread messages together
  let unreadMessageCount = 0
  conversations.forEach((conversation) => {
    unreadMessageCount = unreadMessageCount + conversation?.conversation_attributes?.unread_patient_messages_count ?? 0
  })
  const selectedActiveLiveMsgApt: boolean = liveMsgProviderId === selectedProvider.lyra_id

  const onAttachmentPressed = useCallback(
    (attachment: MessageAttachmentData) => {
      switch (attachment.type) {
        case MessageAttachmentType.DBL:
          if (!selectedProviderLyraId) {
            return
          }

          const activeEpisode = hasActiveEpisode({ episodes, providerId: selectedProviderLyraId })
          if (activeEpisode) {
            navigate(`/secure/index/provider/${selectedProviderLyraId}/rebook`)
          } else {
            navigate(`/secure/index/provider/${selectedProviderLyraId}`)
          }
          togglePopover()
          return
      }
    },
    [episodes, navigate, selectedProviderLyraId, togglePopover],
  )

  const chat = useMemo(() => {
    if (isEmpty(selectedProvider)) {
      return (
        <div styleName='none-selected'>
          <FormattedMessage
            defaultMessage='Please select a provider to message'
            description='prompt user to select a provider they would like to message'
          />
        </div>
      )
    }

    const setInputValueAndStartTypingIndicator = (value: string | undefined) => {
      setInputValue(value)
      onTyping()
    }
    return (
      <Chat
        displayName={selectedProvider?.display_name}
        avatarImage={selectedProvider?.lyraProviderImage.imageUrl}
        chatHeaderTitle={selectedProvider?.display_name}
        chatHeaderSubTitleMsg={getChatHeaderSubTitleMsg({
          countryEmergencyNumbers: [emergencyPhoneNumber],
          isProviderBlocked: selectedProvider?.is_blocked,
          hasActiveAppointment: selectedActiveLiveMsgApt && !isEmpty(activeLiveMsgAppt),
          sessionTime,
          inVideoSession: false,
        })}
        liveSessionBadge={selectedActiveLiveMsgApt && !isEmpty(activeLiveMsgAppt) ? 'LIVE SESSION' : ''}
        messages={data}
        onBack={isMobile ? deselectMessengerProvider : closePopover}
        onSendMessage={submitNewMessage}
        onInputChange={setInputValueAndStartTypingIndicator}
        receiverTyping={receiverTyping}
        contactCarePhoneNumber={contactCarePhoneNumber}
        receiverMsgable={!isEmpty(selectedConversation)}
        receiverBlocked={selectedProvider?.is_blocked}
        isLoading={loading}
        showNewMsgIndicator={newMessageCount > 0}
        unreadMsgCount={newMessageCount}
        inputValue={inputValue}
        showChevron
        showClose={isMobile && providers.length > 1}
        shouldInvert={data.length > 0}
        onChatBubblePressed={onChatBubblePressed}
        onChevronPressed={closePopover}
        conversationDateCreated={selectedConversation?.conversation_date_created}
        onContactCareNavigatorPressed={() => {
          closePopover()
          goToContactCareTeam({
            navigate,
            typeOfRequest: CONTACT_CARE_TEAM_REQUEST_TYPES.CSS_TEAM_SUPPORT,
            mixpanelEntryPoint: MIXPANEL_PAGE.MESSAGES,
          })
        }}
        onAttachmentPressed={onAttachmentPressed}
        sendOnEnter
        scrollInfo={{ scrollToEnd: false, animatedScroll: false }}
        onNewMsgPressed={markMessagesAsRead}
      />
    )
  }, [
    activeLiveMsgAppt,
    closePopover,
    data,
    deselectMessengerProvider,
    emergencyPhoneNumber,
    inputValue,
    isMobile,
    loading,
    markMessagesAsRead,
    navigate,
    newMessageCount,
    onAttachmentPressed,
    onChatBubblePressed,
    onTyping,
    providers.length,
    receiverTyping,
    selectedActiveLiveMsgApt,
    selectedConversation,
    selectedProvider,
    sessionTime,
    setInputValue,
    submitNewMessage,
    contactCarePhoneNumber,
  ])

  const iconStyles = isPopoverOpen
    ? {
        ...customIconStyles,
        ...customIconOpenStyles,
      }
    : customIconStyles
  return (
    <div data-test-id='MessagesPopover' ref={messageContainer}>
      {isPopoverOpen ? <div styleName='mobile-black-background' /> : null}
      <Element name='' id='lyraweb-lyraTherapy-messagesScrollTo'>
        <MessageIcon
          styleName={`message-icon ${isSessionControl ? 'session-control' : ''}`}
          style={iconStyles}
          className='message-icon'
          customBadgeStyle={iconBadgeStyles}
          width={iconSize}
          unreadMessages={isPopoverOpen ? 0 : unreadMessageCount}
          onClick={togglePopover}
          isFilled={iconFilled}
          data-test-id='MessagesPopover-MessageIcon'
          fillColor={iconFillColor ? iconFillColor : isPopoverOpen ? styles.x_primary_action : styles.x_soft_black}
          // @ts-expect-error TS(2322): Type '{ styleName: string; style: Dict | undefined... Remove this comment to see the full error message
          role='button'
          aria-label={intl.formatMessage({
            defaultMessage: 'Toggle messenger',
            description: 'Either to close or open the messenger',
          })}
          aria-expanded={isPopoverOpen}
          tabIndex={0}
          withBadge
          onKeyDown={(e: KeyboardEvent) => {
            if (e.key === 'Enter') {
              togglePopover()
            }
          }}
        />
      </Element>
      <div
        className='lt-messages-popover-container'
        styleName={`popover-container ${providers.length > 1 ? 'large' : ''} ${
          liveMsgProviderId ? 'session-banner' : ''
        }`}
      >
        {isPopoverOpen ? (
          <FocusTrap focusTrapOptions={{ fallbackFocus: '#lyraweb-lyraTherapy-messagesScrollTo' }}>
            <div
              aria-modal
              aria-label={intl.formatMessage({
                defaultMessage: 'Provider Messages',
                description: 'Pop up that contains provider messages',
              })}
              role='dialog'
              style={modalStyle}
            >
              {renderProviderList()}
              <div styleName={`messenger-container ${hideMessenger ? 'hide' : ''}`}>{chat}</div>
            </div>
          </FocusTrap>
        ) : null}
      </div>
    </div>
  )
}

type StateProps = {
  userId: string
  conversationsClient: {
    // Twilio object
    on: (eventName: string, callback: (args: any) => void) => void
    off: (eventName: string, callback: (args: any) => void) => void
    getConversationBySid: (selectedConversationId: string) => any
  }
  conversations: MessageConversation[]
  selectedConversation: ConversationObject
  selectedProvider: ProviderInfo
  providers: Provider[]
  messages: Message[]
  selectedDraft: string
  inLiveMsgSession: boolean
  activeLiveMsgAppt: Appointment | null
  liveMsgProviderId: string
  liveMsgProviderIdToOpen: string
  shownLiveMsgModal: boolean
  selectedConversationId: string
  contactCarePhoneNumber?: string
  emergencyPhoneNumber: string
  episodes: Episode[]
}
const mapStateToProps = ($$state: Map<string, any>): StateProps => {
  const selectedProviderIdx = $$state.getIn(['lyraTherapy', 'messenger', 'selectedProviderIdx'])
  const selectedProvider = $$state.getIn(['lyraTherapy', 'providers', selectedProviderIdx, 'provider']) || Map()
  let selectedConversation: any = undefined
  let selectedDraft: any = undefined
  if (selectedProvider) {
    selectedConversation =
      $$state
        .getIn(['lyraTherapy', 'messenger', 'conversations'], Map())
        .find(
          ($$conversation: Map<string, any>) =>
            $$conversation.get('provider_lyra_id') === selectedProvider.get('lyra_id'),
        ) || Map()
    selectedDraft =
      $$state
        .getIn(['lyraTherapy', 'messenger', 'drafts'])
        .find(
          ($$draft: Map<string, any>) => $$draft.get('conversationId') === selectedConversation?.get('conversation_id'),
        ) || Map()
  }
  const $$activeLiveMsgAppt = $$state.getIn(['lyraTherapy', 'messenger', 'activeLiveMsgAppt']) || Map()
  return {
    userId: $$state.getIn(['user', 'id']),
    conversations: $$state.getIn(['lyraTherapy', 'messenger', 'conversations'], List()),
    providers: $$state.getIn(['lyraTherapy', 'providers'], List()),
    selectedConversation,
    selectedDraft: selectedDraft.get('content', ''),
    selectedProvider,
    messages: $$state.getIn(['lyraTherapy', 'messenger', 'messages'], Map()),
    conversationsClient: $$state.getIn(['lyraTherapy', 'messenger', 'conversationsClient']),
    inLiveMsgSession: $$state.getIn(['lyraTherapy', 'messenger', 'inLiveMsgSession']),
    liveMsgProviderId: $$activeLiveMsgAppt.getIn(['provider', 'lyra_id'], ''),
    liveMsgProviderIdToOpen: $$state.getIn(['lyraTherapy', 'messenger', 'liveMsgProviderIdToOpen']),
    shownLiveMsgModal: $$state.getIn(['lyraTherapy', 'messenger', 'shownModal']),
    selectedConversationId: selectedConversation?.get('conversation_id', ''),
    activeLiveMsgAppt: $$activeLiveMsgAppt.isEmpty() ? null : $$activeLiveMsgAppt,
    contactCarePhoneNumber: getCustomerPhone($$state),
    emergencyPhoneNumber: getCurrentCountryEmergencyNumbersList($$state).toJS()[0],
    episodes: getEpisodes($$state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  const { trackEventWithObj } = MixpanelActions
  return {
    actions: bindActionCreators(
      {
        updateUnreadMessageCount,
        getMessages,
        getMessage,
        incrementUnreadMessageCount,
        submitMessage,
        deselectMessengerProvider,
        selectMessengerProvider,
        trackEventWithObj,
        saveMessageDraft,
        toggleLiveMsgSession,
        setLiveMsgProviderIdToOpen,
        toggleShownLiveMsgModal,
        updateSelectedConversationId,
      },
      dispatch,
    ),
  }
}
const connector = connect(mapStateToProps, mapDispatchToProps)
export default connector(toJS(CSSModules(MessagesPopover, styles, { allowMultiple: true })))
