import React, { FunctionComponent, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useFlags } from '@lyrahealth-inc/shared-app-logic'
import { WellnessCheckInOverview } from '@lyrahealth-inc/ui-core-crossplatform'

const WellnessCheckInOverviewContainer: FunctionComponent = () => {
  const navigate = useNavigate()
  const { isWellnessCheckInEnabled } = useFlags()

  useEffect(() => {
    if (!isWellnessCheckInEnabled) {
      navigate('/')
    }
  }, [isWellnessCheckInEnabled, navigate])

  if (!isWellnessCheckInEnabled) {
    return <></>
  }

  return <WellnessCheckInOverview onCTAPress={() => {}} />
}

export default WellnessCheckInOverviewContainer
