import React, { FunctionComponent } from 'react'

import styled from 'styled-components/native'

import { tID } from '../../utils'
import { Image } from '../image/Image'

const ImageContainer = styled(Image)({
  width: '96px',
  height: '96px',
})

export const WellnessCheckInOverviewIllustration: FunctionComponent = () => {
  return (
    <ImageContainer
      source={require('../../assets/WellnessCheckInOverviewIllustration.png')}
      testID={tID('WellnessCheckInOverviewIllustration')}
    />
  )
}
