import React, { FunctionComponent } from 'react'
import { ImageSourcePropType, Platform, useWindowDimensions, View, ViewStyle } from 'react-native'

import styled from 'styled-components/native'

import { Image } from '../../atoms/image/Image'
import { IS_WEB } from '../../constants'

export interface BackgroundImageContainerProps {
  style?: ViewStyle
  backgroundImageUrl: string | number
  /** Image dimensions should be equal to desktop background asset */
  imageWidth: number
  imageHeight: number
}
const WebImage = styled(Image)<{
  shouldScale: boolean
  backgroundImageUrl: string | number
}>(
  ({ shouldScale, backgroundImageUrl }) =>
    IS_WEB && {
      position: 'fixed',
      flexGrow: 1,
      backgroundImage: `url(${backgroundImageUrl})`,
      backgroundPositionX: 'center',
      backgroundPositionY: 'top',
      backgroundRepeat: 'repeat',
      ...(shouldScale && {
        backgroundSize: 'cover',
      }),
    },
)

const ContainerNative = styled.View({
  flexGrow: 1,
})

export const BackgroundImageContainer: FunctionComponent<BackgroundImageContainerProps> = ({
  children,
  style,
  backgroundImageUrl,
  imageWidth,
  imageHeight,
}) => {
  const { height, width } = useWindowDimensions()
  const shouldScale = width > imageWidth || height > imageHeight
  if (IS_WEB) {
    return (
      <View style={{ flex: 1, ...style }}>
        <WebImage shouldScale={shouldScale} backgroundImageUrl={backgroundImageUrl} />
        {children}
      </View>
    )
  } else {
    return (
      <ContainerNative style={style}>
        <Image
          source={backgroundImageUrl as ImageSourcePropType}
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
          accessibilityIgnoresInvertColors
          contentPosition={{ top: '100%', left: '50%' }}
          contentFit={Platform.OS === 'android' ? 'cover' : 'none'}
        />
        {children}
      </ContainerNative>
    )
  }
}
